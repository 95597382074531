import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import App from "./App.vue";

import http from "./http";
import router from "./router";
import store from "./store";
import config from "./config";
import titleMixin from './mixins/titleMixin'

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap4";
import Form from 'vform'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Editor from '@tinymce/tinymce-vue';
import * as VueGoogleMaps from 'gmap-vue';
import UploadForm from "@/plugins/uploadForm";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Select2 from 'v-select2-component';
import multiselect from 'vue-multiselect'
import VueGoodTablePlugin from 'vue-good-table';
import vSelect from 'vue-select'
import moment from 'moment'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import ToggleButton from 'vue-js-toggle-button'
import VuePreferences from 'vue-preferences';
// import Echo from 'laravel-echo'
// eslint-disable-next-line no-unused-vars
// import pusher from 'pusher-js'
// import VueEcho from 'vue-echo-laravel';

import "@fortawesome/fontawesome-free/";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@popperjs/core";
import "bootstrap";
// import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "admin-lte";
// import "admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css";
import "admin-lte/dist/css/adminlte.min.css";
import 'vue-select/dist/vue-select.css';

// import "admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars.min.js";

import 'vue-good-table/dist/vue-good-table.css'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.min.css';
import * as VueMenu from '@hscmap/vue-menu'

Vue.use(VueMenu);

import VueContext from 'vue-context';
Vue.component(VueContext);

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)


const optionsProgress = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "20px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, optionsProgress);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VuePreferences);
Vue.use(require('vue-shortkey'))

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(ToggleButton)

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component('multiselect', multiselect)
Vue.component("Editor", Editor);
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('toTime', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        // currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('toPercent', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
});

const optionsSwal = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};

Vue.use(VueSweetalert2, optionsSwal);

window.Fire = new Vue();
window.Form = Form;
window.UploadForm = UploadForm;

// window.$ = jquery;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyABy-BnTbPgUfshmnreR6thFhhMVm9ktGk',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Vue.component('v-select', vSelect)
Vue.mixin(titleMixin)
Vue.mixin({
  methods: {
    notEmptyObject(someObject){
      return Object.keys(someObject).length
    }
  }
})

Vue.prototype.$tinyApiKey = "xo6xyc1tjxnqbw6ee3e85r91z2eta43ql67tcvttyqpgf745";
// Vue.use(VueEcho, {
//     broadcaster: 'pusher',
//     // host: '127.0.0.1:6001',
//     key: 'app-key',
//     wsHost: '127.0.0.1',
//     wsPort: '6001',
//     wssPort: '6001',
//     forceTLS: false,
//     encrypted: false,
//     disableStats: true,
//     enabledTransports: ['ws', 'wss'],
// });




Vue.use( CKEditor );
Vue.use(VueGoodTablePlugin);
Vue.component('Select2', Select2);
new Vue({
  http,
  router,
  store,
  config,
  render: h => h(App),
  created() {
    // console.log(this.$echo);
    this.axios.interceptors.request.use(
        config => {
            if(this.$echo != undefined){        
                config.headers["X-Socket-ID"] = this.$echo.connector.pusher.connection.socket_id;
            }

            if (config.showLoader) {
                store.dispatch('loader/pending');
            }
            return config;
        },
        error => {
            if (error.config.showLoader) {
                store.dispatch('loader/done');
            }
            return Promise.reject(error);
        }
    );
    this.axios.interceptors.response.use(
        response => {
          // console.log(response);
            if (response.config.showLoader) {
                store.dispatch('loader/done');
            }
            // delete this.$echo.options.auth.headers.Authorization;
            // this.$echo.options.auth.headers.Authorization = this.$auth.token();
            // console.log(this.$auth.token());
            return response;
        },
        error => {
            let response = error.response;

            if (response.config.showLoader) {
                store.dispatch('loader/done');
            }

            return Promise.reject(error);
        }
    );
  }
}).$mount("#app");
