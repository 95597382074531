export const routes = {
    namespaced: true,
    state: {
        params: {
                sort_field: 'orderdate',
                sort_direction: 'desc',
                per_page: 10,
                order_status_id: null,
                billing_status_id: null,
                date: null
        },
        search: '',
        page: 1,  
    },
    mutations:{
        changeSearch(state, search)
        {
            state.search = search;
        },

        changeSortField(state, field)
        {
            state.params.sort_field = field;
        },

        changeSortDirection(state, field)
        {
            state.params.sort_direction = field;
        },

        setPage(state, field)
        {
            state.page = field;
        }
    }
};