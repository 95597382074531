import Vue from "vue";

import auth from "@websanova/vue-auth/dist/v2/vue-auth.common.js";
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";

Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    // http: Vue.http, // Vue Resource
    router: Vue.router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios, // Axios
    // http: driverHttpVueResource, // Vue Resource
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: "rights",
    forbiddenRedirect: '/dashboard',
    notFoundRedirect: { name: "user-account" },
    loginData: {
      url: "auth/login",
      method: "POST",
      redirect: "/dashboard",
      fetchUser: true
    },
    logoutData: {
      url: "auth/logout",
      method: "POST",
      redirect: "/login",
      makeRequest: true
    },
    fetchData: { url: "auth/user", method: "GET", enabled: true },
    refreshData: {
      url: "auth/refresh",
      method: "GET",
      enabled: true,
      interval: 30
    }
  }
});
