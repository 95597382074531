import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function loadView(view) {
  return () => import(`../views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    component: loadView("Main"),
    meta: {
      auth: true
    },
    children: [
      { path: "/", redirect: { name: "Dashboard" } },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: loadView("Dashboard")
      },
      {
        path: "/news",
        component: loadView("News/Index"),
        name: "news.index",
      },
      {
        path: "/news/:id",
        component: loadView("News/Show"),
        name: "news.show",
      },
      {
        path: "/boards",
        component: loadView("Boards/Index"),
        name: "boards.index",
        meta: {
          auth: "boards.display"
        }
      },
      {
        path: "/calendar",
        component: loadView("Calendar/Index"),
        name: "calendar.index",
      },
      {
        path: "/admin/topics",
        component: loadView("admin/Topics/Index"),
        name: "topics",
        meta: {
          auth: "topic.edit"
        }
      },
      {
        path: "/admin/topics/:id",
        component: loadView("admin/Topics/Details"),
        name: "topics.details",
        meta: {
          auth: "topic.edit"
        }
      },
      {
        path: "/time-tracking",
        name: "Zeiterfassung",
        component: loadView("TimeTracking/Index"),
      },
      { 
        path: "/profile", 
        component: loadView("Profile") 
      },
      {
        path: "/holiday-tracking",
        name: "Urlaub",
        component: loadView("HolidayTracking/Index"),
      },
      {
        path: "/workplan",
        name: "Arbeitsplan",
        component: loadView("Workplan/Index"),
      },
      { 
        path: "/articles",
        component: loadView("Articles/Index"),
        name: "articles.index",
        meta: {
          auth: "articles.display"
        }
      },
      { 
        path: "/articles/:id/edit",
        component: loadView("Articles/Details"),
        name: "articles.details",
        meta: {
          auth: "articles.edit"
        }
      },
      { 
        path: "/articles/create",
        component: loadView("Articles/Create"),
        name: "articles.create",
        meta: {
          auth: "articles.create"
        }
      },
      { 
        path: "/articles/:id/show",
        component: loadView("Articles/Show"),
        name: "articles.show",
        meta: {
          auth: "articles.show"
        }
      },
      { 
        path: "/articles/penner",
        component: loadView("Articles/Penner"),
        name: "articles.penner",
        meta: {
          auth: "articles.create"
        }
      },
      // {
      // path: "/distributors/:id/penner",
      // component: loadView("Distributors/PennerSuppliers"),
      // name: "distributors.penner",
      // },
      { 
        path: "/admin/companies",
        component: loadView("Companies/Index"),
        name: "companies.index",
        meta: {
          auth: "companies.display"
        }
      },
      { 
        path: "/admin/companies/:id/edit",
        component: loadView("Companies/Details"),
        name: "companies.details",
        meta: {
          auth: "companies.edit"
        }
      },
      // { 
      //   path: "/admin/companies/:id",
      //   component: loadView("Companies/Show"),
      //   name: "companies.show",
      //   meta: {
      //     auth: "companies.show"
      //   }
      // },

      // { 
      //   path: "/admin/greetingcards",
      //   component: loadView("Greetingcards/Index"),
      //   name: "greetingcards.index",
      //   meta: {
      //     auth: "greetingcard.display"
      //   }
      // },
      // {
      //   path: "/admin/greetingcards/:id/edit",
      //   component: loadView("Greetingcards/Details"),
      //   name: "greetingcards.details",
      //   meta: {
      //     auth: 'greetingcard.edit'
      //   }
      // },
      // {
      //   path: "/admin/greetingcards/:id",
      //   component: loadView("Greetingcards/Show"),
      //   name: "greetingcards.show",
      //   meta: {
      //     auth: 'greetingcard.show'
      //   }
      // },

      { 
        path: "/admin/giftwrappers",
        component: loadView("Giftwrappers/Index"),
        name: "giftwrappers.index",
        meta: {
          auth: "giftwrapper.display"
        }
      },
      {
        path: "/admin/giftwrappers/:id/edit",
        component: loadView("Giftwrappers/Details"),
        name: "giftwrappers.details",
        meta: {
          auth: 'giftwrapper.edit'
        }
      },
      {
        path: "/admin/giftwrappers/:id",
        component: loadView("Giftwrappers/Show"),
        name: "giftwrappers.show",
        meta: {
          auth: 'giftwrapper.show'
        }
      },

      { 
        path: "/customers",
        component: loadView("Customers/Index"),
        name: "customers.index",
        meta: {
          auth: "customers.display"
        }
      },
      { 
        path: "/customers/create",
        component: loadView("Customers/Create"),
        name: "customers.create",
        meta: {
          auth: "customers.create"
        }
      },
      { 
        path: "/customers/:id/edit",
        component: loadView("Customers/Details"),
        name: "customers.details",
        meta: {
          auth: "customers.edit"
        }
      },
      { 
        path: "/customers/:id",
        component: loadView("Customers/Show"),
        name: "customers.show",
        meta: {
          auth: "customers.show"
        }
      },
      { 
        path: "/categories", 
        component: loadView("Categories/Index"), 
        name: "categories.index",
        meta: {
          auth: 'categories.display'
        }
      },
      { 
        path: "/orders",
        component: loadView("Orders/Index"),
        name: "orders.index",
        meta: {
          auth: 'orders.display'
        }
      },
      { 
        path: "/orders/create/:id?",
        component: loadView("Orders/Create"),
        name: "orders.create",
        meta: {
          auth: 'orders.create'
        }
      },
      { 
        path: "/orders/:id/edit",
        component: loadView("Orders/Details"),
        name: "orders.details",
        meta: {
          auth: 'orders.edit'
        }
      },
      { 
        path: "/orders/:id",
        component: loadView("Orders/Show"),
        name: "orders.show",
        meta: {
          auth: 'orders.show'
        }
      },

      { 
        path: "/abos",
        component: loadView("Abos/Index"),
        name: "abos.index",
        meta: {
          auth: 'abos.display'
        }
      },
      { 
        path: "/abos/create",
        component: loadView("Abos/Create"),
        name: "abos.create",
        meta: {
          auth: 'abos.create'
        }
      },
      { 
        path: "/abos/:id/edit",
        component: loadView("Abos/Details"),
        name: "abos.details",
        meta: {
          auth: 'abos.edit'
        }
      },
      { 
        path: "/abos/:id",
        component: loadView("Abos/Show"),
        name: "abos.show",
        meta: {
          auth: 'abos.show'
        }
      },


      { 
        path: "/offers",
        component: loadView("Offers/Index"),
        name: "offers.index",
        meta: {
          auth: 'offers.display'
        }
      },
      { 
        path: "/offers/create",
        component: loadView("Offers/Create"),
        name: "offers.create",
        meta: {
          auth: 'offers.create'
        }
      },
      { 
        path: "/offers/:id/edit",
        component: loadView("Offers/Details"),
        name: "offers.details",
        meta: {
          auth: 'offers.edit'
        }
      },

      { 
        path: "/producers",
        component: loadView("Producers/Index"),
        name: "producers.index",
        meta: {
          auth: 'producers.display'
        }
      },
      {
        path: "/producers/:id/edit",
        component: loadView("Producers/Details"),
        name: "producers.details",
        meta: {
          auth: 'producers.edit'
        }
      },
      {
        path: "/producers/:id",
        component: loadView("Producers/Show"),
        name: "producers.show",
        meta: {
          auth: 'producers.show'
        }
      },
      { 
        path: "/dealers",
        component: loadView("Dealers/Index"),
        meta: {
          auth: 'dealers.display'
        }
      },
      {
        path: "/dealers/:id/edit/:stats?",
        component: loadView("Dealers/Details"),
        name: "dealers.details",
        meta: {
          auth: 'dealers.edit'
        }
      },
      {
        path: "/dealers/:id",
        component: loadView("Dealers/Show"),
        name: "dealers.show",
        meta: {
          auth: 'dealers.show'
        }
      },
      { 
        path: "/distributors",
        component: loadView("Distributors/Index"),
        meta: {
          auth: 'distributors.display'
        }
      },
      {
        path: "/distributors/:id/edit",
        component: loadView("Distributors/Details"),
        name: "distributors.details",
        meta: {
          auth: 'distributors.edit'
        }
      },
      {
        path: "/distributors/:id/",
        component: loadView("Distributors/Show"),
        name: "distributors.show",
        meta: {
          auth: 'distributors.show'
        }
      },
      { 
        path: "/dealer-orders",
        component: loadView("DealerOrders/Index"),
        name: "dealer-orders.index",
        meta: {
          auth: 'dealer_orders.index'
        }
      },
      { 
        path: "/dealer-orders/create/:id",
        component: loadView("DealerOrders/Create"),
        name: "dealer-orders.create",
        meta: {
          auth: 'dealer_orders.create'
        }
      },
      { 
        path: "/dealer-orders/:id/edit",
        component: loadView("DealerOrders/Details"),
        name: "dealer-orders.details",
        meta: {
          auth: 'dealer_orders.edit'
        }
      },


      { 
        path: "/supplier-orders",
        component: loadView("SupplierOrders/Index"),
        name: "supplier-orders.index",
        meta: {
          auth: 'orders.index'
        }
      },

      { 
        path: "/supplier-orders/create",
        component: loadView("SupplierOrders/Create"),
        name: "supplier-orders.create",
        meta: {
          auth: 'supplier.orders.create'
        }
      },

      { 
        path: "/supplier-orders/create/:id",
        component: loadView("SupplierOrders/CreateSingle"),
        name: "supplier-orders.create-single",
        meta: {
          auth: 'supplier.orders.create'
        }
      },

      { 
        path: "/supplier-orders/:id",
        component: loadView("SupplierOrders/Show"),
        name: "supplier-orders.show",
        meta: {
          auth: 'supplier.orders.show'
        }
      },
      { 
        path: "/supplier-orders/:id/edit",
        component: loadView("SupplierOrders/Details"),
        name: "supplier-orders.details",
        meta: {
          auth: 'supplier.orders.edit'
        }
      },
      //Store Orders
      { 
        path: "/store-orders",
        component: loadView("StoreOrders/Index"),
        name: "store-orders.index",
        meta: {
          auth: 'store.order.index'
        }
      },
      { 
        path: "/store-orders/create",
        component: loadView("StoreOrders/Create"),
        name: "store-orders.create",
        meta: {
          auth: 'store.order.create'
        }
      },
      { 
        path: "/store-orders/:id",
        component: loadView("StoreOrders/Show"),
        name: "store-orders.show",
        meta: {
          auth: 'store.order.show'
        }
      },
      { 
        path: "/store-orders/:id/edit",
        component: loadView("StoreOrders/Details"),
        name: "store-orders.details",
        meta: {
          auth: 'store.order.edit'
        }
      },
  
      { 
        path: "/wholesaler-orders",
        component: loadView("WholesalerOrders/Index"),
        name: "wholesaler-orders.index",
        meta: {
          auth: 'supplier.orders.index'
        }
      },

      { 
        path: "/wholesaler-orders/create/:id",
        component: loadView("WholesalerOrders/Create"),
        name: "wholesaler-orders.create",
        meta: {
          auth: 'supplier.orders.create'
        }
      },

      { 
        path: "/wholesaler-orders/:id/edit",
        component: loadView("WholesalerOrders/Details"),
        name: "wholesaler-orders.details",
        meta: {
          auth: 'supplier.orders.edit'
        }
      },

      { 
        path: "/credits",
        component: loadView("Credits/Index"),
        name: "credits.index",
        meta: {
          auth: 'credits.display'
        }
      },

      { 
        path: "/credits/create/:id?",
        component: loadView("Credits/Create"),
        name: "credits.create",
        meta: {
          auth: 'credits.create'
        }
      },

      {
        path: "/credits/:id",
        component: loadView("Credits/Show"),
        name: "credits.show",
        meta: {
          auth: 'credits.show'
        }
      },

      { 
        path: "/stornos",
        component: loadView("Stornos/Index"),
        name: "stornos.index",
        meta: {
          auth: 'stornos.display'
        }
      },
      {
        path: "/stornos/:id",
        component: loadView("Stornos/Details"),
        name: "stornos.details",
        meta: {
          auth: 'stornos.show'
        }
      },

      { 
        path: "/datev",
        component: loadView("Datev/Index"),
        name: "datev.index",
        meta: {
          auth: 'datev.display'
        }
      },

      { 
        path: "/vouchers",
        component: loadView("Vouchers/Index"),
        name: "vouchers.index",
        meta: {
          auth: 'vouchers.display'
        }
      },
      { 
        path: "/statistics/general",
        component: loadView("Statistics/General"),
        name: "statistics.general",
        meta: {
          auth: 'statistics.general'
        }
      },
      { 
        path: "/statistics/sales",
        component: loadView("Statistics/Sales"),
        name: "statistics.sales",
        meta: {
          auth: 'statistics.sales'
        }
      },
      { 
        path: "/statistics/dealers",
        component: loadView("Statistics/Dealers"),
        name: "statistics.dealers",
        meta: {
          auth: 'statistics.sales'
        }
      },

      { 
        path: "/statistics/products",
        component: loadView("Statistics/Products"),
        name: "statistics.products",
        meta: {
          auth: 'statistics.products'
        }
      },

      { 
        path: "/production/products",
        component: loadView("Products/Index"),
        meta: {
          auth: 'products.index'
        }
      },
      {
        path: "/production/products/:id/edit",
        component: loadView("Products/Details"),
        name: "products.details",
        meta: {
          auth: 'products.edit'
        }
      },


      { 
        path: "/production/wholesalers",
        component: loadView("Wholesalers/Index"),
        meta: {
          auth: 'wholesalers.display'
        }
      },
      {
        path: "/production/wholesalers/:id",
        component: loadView("Wholesalers/Details"),
        name: "wholesalers.details",
        meta: {
          auth: 'wholesalers.show'
        }
      },


      { 
        path: "/production/labels",
        component: loadView("Labels/Index"),
        name: "labels.index",
        meta: {
          auth: 'labels.display'
        }
      },
      { 
        path: "/production/labels/:id/edit",
        component: loadView("Labels/Details"),
        name: "labels.details",
        meta: {
          auth: 'labels.edit'
        }
      },

      {
        path: "/production/labels/:id",
        component: loadView("Labels/Show"),
        name: "labels.show",
        meta: {
          auth: 'labels.show'
        }
      },


      { 
        path: "/settings/variation",
        component: loadView("settings/VariationGroups"),
        meta: {
          auth: 'variations.display'
        }
      },
      { 
        path: "/settings/manss-blacklist",
        component: loadView("settings/ManssBlacklist"),
        // meta: {
          auth: 'settings.manss_blacklist.display'
        // }
      },

      { 
        path: "/settings/variation/:id/edit",
        component: loadView("settings/VariationGroupsDetails"),
        name: "VariationGroup.details",
        meta: {
          auth: 'variations.edit'
        }
      },

      { 
        path: "/settings/variation/:id",
        component: loadView("settings/VariationGroupsShow"),
        name: "VariationGroup.show",
        meta: {
          auth: 'variations.show'
        }
      },



      { 
        path: "/settings/taxes",
        component: loadView("settings/Taxes"),
        meta: {
          auth: 'taxes.display'
        }
      },

      { 
        path: "/settings/jobs",
        component: loadView("settings/Jobs"),
        meta: {
          auth: 'jobs.display'
        }
      },
      {
        path: "/settings/faqs",
        component: loadView("settings/Faqs"),

      },

      { 
        path: "/settings/stores",
        component: loadView("settings/Stores"),
        meta: {
            auth: 'stores.display'
          }
      },

      { 
        path: "/settings/warehouses",
        component: loadView("settings/Warehouses"),
        meta: {
            auth: 'warehouses.display'
          }
      },

      { 
        path: "/settings/units",
        component: loadView("settings/Units"),
        meta: {
          auth: 'units.display'
        }
      },
      { 
        path: "/settings/commercial_classes",
        component: loadView("settings/CommercialClasses"),
        meta: {
          auth: 'commercial_classes.display'
        }
      },
      { 
        path: "/settings/countries",
        component: loadView("settings/Countries"),
        meta: {
          auth: 'countries.display'
        }
      },
      { 
        path: "/settings/deposit",
        component: loadView("settings/Deposit"),
        meta: {
          auth: 'deposits.display'
        }
      },
      { 
        path: "/settings/groups", 
        component: loadView("settings/Groups"), 
        meta: {
          auth: 'groups.display'
        }
      },
      { 
        path: "/settings/healthy-claims", 
        component: loadView("settings/HealthyClaims"), 
        meta: {
          auth: 'healthy-claims.display'
        }
      },

      { 
        path: "/settings/calendar", 
        component: loadView("settings/Calendar"), 
        meta: {
          auth: 'calendar.display'
        }
      },

      { 
        path: "/admin/users", 
        component: loadView("admin/Users"),
        meta: {
          auth: 'users.index'
        }
      },
      { 
        path: "/admin/roles", 
        component: loadView("admin/Roles"),
        meta: {
          auth: 'roles.index'
        }
      },
      { 
        path: "/admin/permissions",
        component: loadView("admin/Permissions"),
        meta: {
            auth: 'permissions.index'
          }
      },
      { 
        path: "/admin/groups",
        component: loadView("admin/PermissionGroups"),
        meta: {
          auth: 'permission_groups.index'
        }
      },
      { 
        path: "/cars",
        component: loadView("Cars/Index"),
        name: 'cars.index',
        meta: {
          auth: 'car.index'
        }
      },
      { 
        path: "/cars/create",
        component: loadView("Cars/Create"),
        name: 'cars.create',
        meta: {
          auth: 'car.create'
        }
      },
      { 
        path: "/cars/:id/edit",
        component: loadView("Cars/Details"),
        name: 'cars.details',
        meta: {
          auth: 'car.edit'
        }
      },


      //Routenplaner
      { 
        path: "/routes",
        component: loadView("Routes/Index"),
        name: 'routes.index',
        meta: {
          auth: 'routes.index'
        }
      },
      { 
        path: "/routes/:id/edit",
        component: loadView("Routes/Details"),
        name: 'routes.details',
        meta: {
          auth: 'routes.edit'
        },
      },
      {
        path: "/routes/:id/show",
        component: loadView("Routes/Show"),
        name: 'routes.show',
        meta: {
          auth: 'routes.show'
        }
      },
      {
        path: "/routes/create",
        component: loadView("Routes/Create"),
        name: 'routes.create',
        meta: {
          auth: 'routes.create'
        }
      },
      {
        path: "/management/employees",
        component: loadView("Employees/Index"),
        name: 'employees.index',
        meta: {
          auth: 'employees.display'
        }
      },
      {
        path: "/management/employees/create",
        component: loadView("Employees/Create"),
        name: 'employees.create',
        meta: {
          auth: 'employees.create'
        }
      },
      {
        path: "/management/employees/:id/time-tracking",
        component: loadView("Employees/TimeTracking"),
        name: 'employees.time-tracking',
        meta: {
          // auth: ''
        }
      },
      {
        path: "/management/employees/:id/edit",
        component: loadView("Employees/Details"),
        name: 'employees.edit',
        meta: {
          auth: 'employees.display'
        }
      },
      {
        path: "/management/employee-groups",
        component: loadView("EmployeeGroups/Index"),
        name: 'employee.groups.index',
        meta: {
          auth: 'employee.groups.index'
        }
      },
      {
        path: "/management/holidays",
        component: loadView("EmployeeHolidays/Index"),
        name: 'employee.holidays.index',
        meta: {
          auth: 'employee.holidays.index'
        }
      },
      {
        path: "/management/workplan",
        component: loadView("EmployeeWorkplan/Index"),
        name: 'employee.workplan.index',
        meta: {
          auth: 'employee.workplan.index'
        }
      },
      {
        path: "/settings/holidays",
        component: loadView("Holidays/Index"),
        name: 'holidays.index',
        meta: {
          auth: 'holidays.period.display'
        }
      },
      {
        path: "/shelves",
        name: "Regalverwaltung",
        component: loadView("Shelves/Index")
      },
    ]
  },
  {
    path: "/login",
    component: loadView("Login"),
    name: "Login",
    meta: {
      auth: false
    }
  },
  {
    path: "/boards/:id",
    component: loadView("Boards/Details"),
    name: "boards.details",
    meta: {
      auth: true
    }
  },
];

Vue.router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default Vue.router;
