export const wholesalers = {
    namespaced: true,
    state: {
        params: {
                sort_field: 'name',
                sort_direction: 'asc',
                per_page: 25,
            },
        search: '',
        page: 1,
    },
    mutations:{
        changeSearch(state, search)
        {
            state.search = search;
        },

        changeSortField(state, field)
        {
            state.params.sort_field = field;
        },

        changeSortDirection(state, field)
        {
            state.params.sort_direction = field;
        },

        setPage(state, field)
        {
            state.page = field;
        }
    }
};