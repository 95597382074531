import Vue from 'vue'
import Vuex from 'vuex'
import { loader } from './modules/loader';
import { orders } from './modules/orders';
import { abos } from './modules/abos';
import { offers } from './modules/offers';
import { articles } from './modules/articles';
import { wholesalers } from './modules/wholesalers';
import { products } from './modules/products';
import { labels } from './modules/labels';
import { dealers } from './modules/dealers';
import { distributors } from './modules/distributors';
import { customers } from './modules/customers';
import { supplier_orders } from './modules/supplier_orders';
import { dealer_orders } from './modules/dealer_orders';
import { wholesaler_orders } from './modules/wholesaler_orders';
import { store_orders } from './modules/store_orders'
import { routes } from './modules/routes';
import { news } from './modules/news';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // params: {
    //             sort_field: 'created_at',
    //             sort_direction: 'desc',
    //             producer_id: '',
    //             tax_id: '',
    //             is_active: '',
    //             per_page: 25,
    //             group_id: '',
                
    //         },
    // search: '',
    // page: 1,
    
  },
  mutations: {
    // changeSearch(state, search)
    // {
    //   state.search = search;
    // },

    // changeSortField(state, field)
    // {
    //   state.params.sort_field = field;
    // },

    // changeSortDirection(state, field)
    // {
    //   state.params.sort_direction = field;
    // },

    // changeProducer(state, field)
    // {
    //   state.params.producer_id = field;
    // },

    // changeTax(state, field)
    // {
    //   state.params.tax_id = field;
    // },

    // changeActive(state, field)
    // {
    //   state.params.is_active = field;
    // },

    // changePerPage(state, field)
    // {
    //   state.params.per_page = field;
    // },

    // changeGroup(state, field)
    // {
    //   state.params.group_id = field;
    // },

    // changePage(state, field)
    // {
    //   state.page = field;
    // },
  },
  actions: {
  },
  modules: {
    loader,
    orders,
    abos,
    articles,
    wholesalers,
    products,
    labels,
    dealers,
    distributors, 
    customers, 
    supplier_orders,
    wholesaler_orders,
    store_orders,
    dealer_orders,
    routes,
    offers,
    news
  },
  getters: {
    // search: state => state.search,
    // sort_field: state => state.params.sort_field,
    // sort_direction: state => state.params.sort_direction,
    // producer_id: state => state.params.producer_id,
    // tax_id: state => state.params.tax_id,
    // is_active: state => state.params.is_active,
    // per_page: state => state.params.per_page,
    // group_id: state => state.params.group_id,
    // page: state => state.page,
  }
})
